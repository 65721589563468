import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaRegQuestionCircle } from "react-icons/fa";
import QuizData from "./QuizData";
import Header from "../Home/Header";
import QuizResult from "./QuizResult";

const QuizPlayground = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const { quizTitle } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedQuiz = QuizData.quizzes.find(
    (title) => title.name.replace(/\s+/g, "-").toLowerCase() === quizTitle
  );

  if (!selectedQuiz) {
    return (
      <div className="text-center mt-20 text-gray-700 font-bold text-xl">
        Quiz not found
      </div>
    );
  }

  const currentQuestion = selectedQuiz.questions[currentQuestionIndex];
  const totalQuestions = selectedQuiz.questions.length;

  const handleOptionClick = (questionIndex, optionIndex) => {
    const selectedOption = currentQuestion.options[optionIndex];
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: selectedOption.score,
    }));
  };

  const handleNextClick = () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      calculateScore();
    }
  };

  const handlePreviousClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const calculateScore = () => {
    const score = Object.values(answers).reduce((acc, curr) => acc + curr, 0);
    setTotalScore(score);
    setShowResult(true);
  };

  const isLastQuestion = currentQuestionIndex === totalQuestions - 1;

  const getResultMessage = () => {
    const { scoring } = selectedQuiz;
    let resultMessage = "";

    for (const [range, message] of Object.entries(scoring)) {
      const [min, max] = range.split("-").map(Number);
      if (totalScore >= min && totalScore <= max) {
        resultMessage = message;
        break;
      }
    }
    return resultMessage;
  };

  if (showResult) {
    const resultMessage = getResultMessage();
    return <QuizResult resultMessage={resultMessage} />;
  }

  return (
    <div className="bg-blue-100 min-h-svh sm:min-h-screen pt-10 flex flex-col justify-center font-montserrat pb-6 md:pb-0">
      <Header />
      <div className="max-w-3xl lg:max-w-5xl bg-blue-100 md:bg-white md:rounded-xl md:shadow-lg p-4 sm:mx-auto sm:p-8">
        <div className="flex flex-col max-sm:mt-6 md:flex-row justify-between bg-white max-md:p-4 rounded-t-lg md:rounded-lg items-center max-md:items-start border-b border-gray-200 pb-2 md:mb-4">
          <h2 className="sm:w-1/2 pb-1 text-xl md:text-3xl font-bold text-blue-700">
            {selectedQuiz.name}
          </h2>
          <div className="md:bg-blue-600 max-md:underline md:text-white rounded-lg md:px-4 pt-2 md:py-2 text-sm font-medium">
            Question {currentQuestionIndex + 1} of {totalQuestions}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row md:space-x-6 mx-auto max-w-6xl ">
          <div className="lg:w-3/4 flex flex-col bg-white p-4 rounded-b-lg md:rounded-lg">
            <div className="mb-6">
              <p className=" font-semibold text-gray-700 mb-4">
                {currentQuestionIndex + 1}. {currentQuestion.question}
              </p>
              <ul className="space-y-2 sm:space-y-4">
                {currentQuestion.options.map((option, index) => (
                  <li
                    key={index}
                    className={`p-3 text-sm rounded-lg cursor-pointer text-gray-700 transition-colors ${
                      answers[currentQuestionIndex] === option.score
                        ? "bg-blue-600 text-white"
                        : "bg-gray-50 hover:bg-blue-600 hover:text-white border border-gray-300"
                    }`}
                    onClick={() => {
                      handleOptionClick(currentQuestionIndex, index);
                      if (currentQuestionIndex < totalQuestions - 1) {
                        handleNextClick();
                      }
                    }}
                  >
                    {option.text}
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex justify-between items-center text-sm mt-auto mb-4">
              <button
                onClick={handlePreviousClick}
                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:pointer-events-none disabled:bg-gray-300 disabled:text-gray-600"
                disabled={currentQuestionIndex === 0}
              >
                <FaArrowLeft /> Previous
              </button>
              <button
                onClick={handleNextClick}
                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:pointer-events-none disabled:bg-gray-300 disabled:text-gray-600"
                disabled={answers[currentQuestionIndex] === undefined}
              >
                {isLastQuestion ? "Submit" : "Next"} 
                {!isLastQuestion && <FaArrowRight />}
              </button>
            </div>
          </div>
          <div className="lg:w-1/4 flex flex-col justify-between w-full bg-white p-4 border mt-6 md:mt-0 rounded-lg shadow-sm">
            <div>
              <p className="text-gray-600 mb-6 text-sm">
                {selectedQuiz.description}
              </p>
              <div className="text-gray-700 space-y-3 border-t border-gray-200 pt-4">
                <div className="flex items-center text-sm font-medium space-x-2">
                  <span className=" ">📋 Questions: {totalQuestions}</span>
                </div>
                <div className="flex items-center text-sm font-medium space-x-2">
                  <span className="">
                    ⏰ Time: {selectedQuiz.estimatedTime}
                  </span>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate("/quizzes")}
              className="mt-6 text-sm block w-full py-2 rounded-lg text-center text-white bg-blue-600 font-medium hover:bg-blue-700 shadow-lg hover:shadow-xl transition-shadow"
            >
              Back to Quizzes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizPlayground;
