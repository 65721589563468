const blogData = [
  {
    id: 1,
    category: "Anxiety",
    blogs: [
      {
        id: 101,
        title: "Managing Anxiety",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["mental health", "university", "self-care"],
        image: "./img/anxiety.jpg",
        summary:
          "Learn how to manage anxiety as a university student with practical strategies and understanding the root causes.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "Anxiety is something many university students experience. With academic responsibilities, social pressures, and the looming uncertainty of the future, anxiety can start to feel overwhelming. But it's essential to recognize when this normal feeling of worry becomes a disorder and learn how to manage it.",
          },
          sections: [
            {
              id: 1,
              heading: "What is Anxiety?",
              content: [
                {
                  type: "paragraph",
                  text: "Anxiety is not just feeling nervous before an exam or worried about a deadline. It's a persistent feeling of worry or fear that can continue for weeks or months and begin to interfere with daily activities.",
                },
                {
                  type: "paragraph",
                  text: "For many students, the experience of moving away from home, dealing with financial stress, and adjusting to new social environments contributes significantly to increased anxiety. But it's important to remember that anxiety isn't something to be ashamed of. It's a common issue that can be managed with the right support.",
                },
              ],
            },
            {
              id: 2,
              heading: "Causes of Anxiety in University Students",
              content: [
                {
                  type: "paragraph",
                  text: "Several factors contribute to the rising anxiety levels in university students:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Academic Pressure",
                      text: "The pressure to perform well, maintain scholarships, and succeed academically can create chronic stress.",
                    },
                    {
                      title: "Financial Concerns",
                      text: "The burden of tuition fees, loans, and managing living expenses while studying can add to mental strain.",
                    },
                    {
                      title: "Social Stress",
                      text: "Making new friends, dealing with peer pressure, and managing relationships can add to the mix.",
                    },
                    {
                      title: "Future Uncertainty",
                      text: "Concerns about career prospects, internships, and what happens after graduation often cause anxiety.",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "Coping Strategies for Managing Anxiety",
              content: [
                {
                  type: "paragraph",
                  text: "While anxiety may feel overwhelming, there are practical steps you can take to manage it:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Practice Mindfulness",
                      text: "Mindfulness exercises, like meditation, deep breathing, or progressive muscle relaxation, can help you regain focus and calm your mind.",
                    },
                    {
                      title: "Maintain a Healthy Routine",
                      text: "Sleep, diet, and exercise are vital in managing anxiety. Regular exercise releases endorphins, which help combat stress and anxiety.",
                    },
                    {
                      title: "Stay Organized",
                      text: "Use a planner or digital apps to organize your tasks, deadlines, and study schedule. Breaking your work into manageable chunks reduces feelings of being overwhelmed.",
                    },
                    {
                      title: "Seek Support",
                      text: "Talking to friends, family, or a counselor about how you're feeling can provide relief.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "Anxiety during college students is common but manageable. By recognizing the signs and taking proactive steps, you can navigate through it and enjoy your university experience. Remember that seeking help from professionals, like a counselor or therapist, is a sign of strength, not weakness.",
          },
        },
      },
      {
        id: 102,
        title: "Dealing with Social Anxiety",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["social anxiety", "mental health", "college life"],
        image: "./img/socialAnxiety.jpg",
        summary:
          "Explore social anxiety, its symptoms, and effective coping strategies for college students to manage their fears and thrive socially.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "College life is full of social interactions — from group projects to making new friends, attending parties, or speaking up in class. For some students, these situations can feel overwhelming due to social anxiety. Social anxiety disorder is a condition where everyday interactions cause significant fear, self-consciousness, and embarrassment.",
          },
          sections: [
            {
              id: 1,
              heading: "What is Social Anxiety?",
              content: [
                {
                  type: "paragraph",
                  text: "Social anxiety is a persistent fear of being judged, embarrassed, or humiliated in social settings. It's more than just being shy or nervous in new situations. For those with social anxiety, even simple tasks like answering a question in class, making small talk, or eating in public can cause extreme stress.",
                },
              ],
            },
            {
              id: 2,
              heading: "Common Symptoms of Social Anxiety",
              content: [
                {
                  type: "paragraph",
                  text: "Social anxiety can show up in various ways, including:",
                },
                {
                  type: "list",
                  items: [
                    {
                      text: "Physical Symptoms: Sweating, blushing, trembling, feeling your heart race, or having trouble breathing in social situations.",
                    },
                    {
                      text: "Emotional Symptoms: Intense fear of being judged, embarrassed, or criticized. Worrying for days or weeks before a social event.",
                    },
                    {
                      text: "Avoidance Behavior: Avoiding social situations, skipping classes or parties, or leaving events early to escape uncomfortable feelings.",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "How Social Anxiety Affects University Students",
              content: [
                {
                  type: "paragraph",
                  text: "For students with social anxiety, university life can be particularly challenging:",
                },
                {
                  type: "list",
                  items: [
                    {
                      text: "Class Participation: Speaking up in class or working in groups can trigger anxiety, making it hard to engage with peers or professors.",
                    },
                    {
                      text: "Socializing: Making new friends, attending parties, or even walking around campus can feel like overwhelming experiences.",
                    },
                    {
                      text: "Academic Impact: Anxiety may lead students to skip presentations, avoid group projects, or fail to ask questions, all of which can negatively impact their academic performance.",
                    },
                  ],
                },
              ],
            },
            {
              id: 4,
              heading: "Coping Strategies for Social Anxiety",
              content: [
                {
                  type: "paragraph",
                  text: "There are ways to manage social anxiety, allowing you to feel more comfortable and confident in social situations:",
                },
                {
                  type: "list",
                  items: [
                    {
                      text: "Challenge Negative Thoughts: Social anxiety often stems from negative thoughts like 'Everyone is judging me.' Start recognizing these thoughts and challenge them. Ask yourself: 'Is this thought really true?'",
                    },
                    {
                      text: "Gradual Exposure: Try slowly exposing yourself to social situations that make you anxious. Start small, like chatting with a classmate, and gradually work up to larger groups or events.",
                    },
                    {
                      text: "Practice Deep Breathing: Breathing exercises can help calm your nerves before or during a social situation. Try taking slow, deep breaths to lower your anxiety levels.",
                    },
                    {
                      text: "Join Clubs or Groups: Participating in clubs or activities where you share common interests with others can help reduce anxiety since the focus is on a shared passion, not on socializing itself.",
                    },
                    {
                      text: "Seek Professional Help: Therapy, especially Cognitive Behavioral Therapy (CBT), can be very effective for social anxiety. A mental health professional can help you understand and change the thought patterns that contribute to your anxiety.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "Social anxiety is a challenging but manageable condition. By slowly working on exposure to social situations and seeking help when needed, you can reduce the hold anxiety has on your life and enjoy your university experience more fully.",
          },
        },
      },
    ],
  },
  {
    id: 2,
    category: "Mental Health",
    blogs: [
      {
        id: 201,
        title: "Impact of Social Media on Mental Health",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["mental health", "social media", "self-care"],
        image: "./img/socialMedia.jpg",
        summary:
          "Explore the impact of social media on mental health, including the risks of comparison culture, FOMO, and ways to create healthier online habits.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "Social media is woven into the daily lives of university students. Platforms like Instagram, and Snapchat have become essential ways to connect with friends, stay updated, and share experiences. But the constant interaction with social media also has a downside. It can negatively impact mental health, leading to anxiety, depression, and lower self-esteem.",
          },
          sections: [
            {
              id: 1,
              heading: "How Social Media Affects Mental Health",
              content: [
                {
                  type: "paragraph",
                  text: "While social media offers many benefits, such as staying connected and informed, it also has some adverse effects. Here's how it can impact your mental well-being:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Comparison Culture",
                      text: "Social media is often a highlight reel of people's lives. You see carefully curated, happy moments — vacations, parties, achievements — which can make you feel like you're falling behind. Constantly comparing your life to others can harm your self-esteem and lead to feelings of inadequacy.",
                    },
                    {
                      title: "Fear of Missing Out (FOMO)",
                      text: "Seeing friends at events, traveling, or hanging out without you can trigger a fear of missing out, making you feel isolated or left out.",
                    },
                    {
                      title: "Sleep Disruption",
                      text: "Excessive screen time, especially before bed, interferes with sleep patterns, leading to sleep deprivation, which further affects mental health.",
                    },
                    {
                      title: "Cyberbullying and Negative Comments",
                      text: "Negative interactions, like trolling or cyberbullying, can cause emotional distress and even lead to anxiety or depression.",
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              heading: "Recognizing the Signs of Social Media Burnout",
              content: [
                {
                  type: "paragraph",
                  text: "If you're spending too much time on social media, it can start to take a toll on your mental health. Here are some signs you might need a break:",
                },
                {
                  type: "list",
                  items: [
                    {
                      text: "Feeling anxious when you're away from your phone or social media",
                    },
                    {
                      text: "Constantly checking for updates, likes, or messages",
                    },
                    {
                      text: "Low self-esteem or feeling like you're not doing enough in life",
                    },
                    {
                      text: "Difficulty sleeping due to late-night scrolling",
                    },
                    {
                      text: "Stress or worry about missing out on social events or online trends",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "How to Create Healthy Social Media Habits",
              content: [
                {
                  type: "paragraph",
                  text: "It's important to find a balance between enjoying social media and protecting your mental health. Here are a few strategies to manage your social media use:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Set Time Limits",
                      text: "Many apps allow you to set daily time limits on social media. This helps you avoid spending hours scrolling mindlessly.",
                    },
                    {
                      title: "Unfollow Accounts that Affect Your Self-Esteem",
                      text: "If certain accounts make you feel bad about yourself, it's okay to unfollow them. Fill your feed with positive, motivational content.",
                    },
                    {
                      title: "Take Social Media Breaks",
                      text: "Regularly taking breaks from social media can give you time to recharge and focus on your mental health.",
                    },
                    {
                      title: "Engage in Real-Life Activities",
                      text: "Spend time offline by engaging in hobbies, meeting friends in person, and participating in activities that don't involve screens.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "While social media has its benefits, it's essential to be mindful of its impact on your mental health. By setting boundaries and taking breaks, you can enjoy social media without letting it negatively affect your well-being.",
          },
        },
      },
      {
        id: 202,
        title: "Balancing Academics and Mental Health",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["mental health", "academics", "college life"],
        image: "./img/academics.jpg",
        summary:
          "Discover the importance of mental health for college students and practical tips to maintain a healthy balance between academics and well-being.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "College students are often under intense pressure to excel academically. Between exams, assignments, and maintaining high grades, students sometimes neglect their mental health in the process. Balancing academics and mental well-being is critical to a successful university experience, and this article will explore how to achieve that balance.",
          },
          sections: [
            {
              id: 1,
              heading: "Why Mental Health is Important",
              content: [
                {
                  type: "paragraph",
                  text: "Your mental health is just as important as your physical health. When you're mentally healthy, you're better able to handle stress, maintain good relationships, and perform well in academics. However, when mental health is neglected, it can lead to conditions like anxiety, depression, and burnout, all of which negatively impact academic performance and overall well-being.",
                },
              ],
            },
            {
              id: 2,
              heading: "Signs You're Neglecting Your Mental Health",
              content: [
                {
                  type: "paragraph",
                  text: "It's easy to get caught up in academic work and ignore warning signs that your mental health is suffering. Here are some common indicators:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Constant Fatigue",
                      text: "Feeling tired all the time, even after a good night's sleep, can be a sign of stress or burnout.",
                    },
                    {
                      title: "Difficulty Concentrating",
                      text: "If you're finding it harder to focus on your studies, it could be because of mental fatigue or anxiety.",
                    },
                    {
                      title: "Loss of Interest",
                      text: "You may lose interest in activities that you once enjoyed, including hobbies or spending time with friends.",
                    },
                    {
                      title: "Mood Changes",
                      text: "Irritability, sadness, or emotional numbness can be signs of declining mental health.",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading:
                "Practical Tips for Balancing Academics and Mental Health",
              content: [
                {
                  type: "paragraph",
                  text: "Here are some practical tips to help you balance your academic responsibilities with your mental well-being:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Time Management",
                      text: "Creating a balanced schedule can help reduce stress. Plan your study time, but also make time for breaks and relaxation.",
                    },
                    {
                      title: "Prioritize Sleep",
                      text: "A regular sleep schedule is essential for maintaining both mental and physical health. Aim for at least 7-8 hours of sleep per night.",
                    },
                    {
                      title: "Exercise Regularly",
                      text: "Physical activity, even if it's just a 20-minute walk, can significantly improve your mood and reduce stress.",
                    },
                    {
                      title: "Practice Mindfulness",
                      text: "Mindfulness techniques like meditation can help you stay grounded and manage anxiety. Apps like Headspace or Calm are great tools for this.",
                    },
                    {
                      title: "Stay Connected",
                      text: "Don't isolate yourself. Make time to talk to friends or family, and seek support when you're feeling overwhelmed.",
                    },
                    {
                      title: "Know When to Ask for Help",
                      text: "If you're feeling persistently overwhelmed, it's important to reach out to a campus counselor or mental health professional for guidance.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "Maintaining a balance between academics and mental health is key to thriving in university. By practicing self-care, managing your time well, and seeking support when needed, you can succeed both academically and personally.",
          },
        },
      },
    ],
  },
  {
    id: 3,
    category: "Stress",
    blogs: [
      {
        id: 301,
        title: "Understanding and Managing Stress in College",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["stress", "college life", "mental health"],
        image: "./img/collegeStress.jpg",
        summary:
          "Explore the nature of stress in college, common causes, symptoms, and effective coping strategies for managing stress.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "College life can be thrilling, but it can also come with its fair share of stress. Between juggling academics, extracurriculars, social life, and possibly a part-time job, it's easy to feel overwhelmed. Stress is a normal part of life, but when it becomes constant and unmanageable, it can lead to burnout, anxiety, and even physical health problems.",
          },
          sections: [
            {
              id: 1,
              heading: "What is Stress?",
              content: [
                {
                  type: "paragraph",
                  text: "Stress is your body's natural response to challenging situations. In small doses, it can help motivate you to study for exams or meet deadlines. However, when stress becomes chronic — meaning it doesn't go away even after the stressful situation is over — it can harm both your mental and physical health. Many students don't realize they are dealing with chronic stress until it starts affecting their day-to-day life.",
                },
              ],
            },
            {
              id: 2,
              heading: "Common Causes of Stress in University",
              content: [
                {
                  type: "paragraph",
                  text: "There are many reasons why university students might experience stress. Some of the most common causes include:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Academic Pressure",
                      text: "The need to achieve high grades, meet deadlines, and excel in exams can create a lot of pressure.",
                    },
                    {
                      title: "Time Management",
                      text: "Balancing classes, study time, and social life can be challenging.",
                    },
                    {
                      title: "Financial Worries",
                      text: "Tuition fees, rent, and daily expenses often cause financial stress.",
                    },
                    {
                      title: "Social Expectations",
                      text: "Navigating relationships, friendships, and fitting in with peers can add another layer of stress.",
                    },
                    {
                      title: "Future Concerns",
                      text: "Uncertainty about future careers, internships, or job prospects also contributes to stress.",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "Symptoms of Stress",
              content: [
                {
                  type: "paragraph",
                  text: "Stress affects everyone differently, but common symptoms include:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Physical Symptoms",
                      text: "Headaches, fatigue, upset stomach, muscle tension, or trouble sleeping.",
                    },
                    {
                      title: "Emotional Symptoms",
                      text: "Feeling overwhelmed, irritable, or anxious. You might also find it hard to relax, even when you have free time.",
                    },
                    {
                      title: "Cognitive Symptoms",
                      text: "Difficulty concentrating, forgetfulness, or negative thoughts.",
                    },
                    {
                      title: "Behavioral Symptoms",
                      text: "Procrastination, avoiding social interactions, overeating, or developing unhealthy coping mechanisms like smoking or drinking.",
                    },
                  ],
                },
              ],
            },
            {
              id: 4,
              heading: "Coping Strategies for Stress Management",
              content: [
                {
                  type: "paragraph",
                  text: "Managing stress effectively can help improve your well-being and performance. Here are some strategies:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Prioritize Time Management",
                      text: "Use tools like planners or apps to organize your day, setting realistic goals and breaking larger tasks into smaller, manageable steps.",
                    },
                    {
                      title: "Practice Relaxation Techniques",
                      text: "Incorporate relaxation exercises like deep breathing, meditation, or yoga into your routine to calm your mind.",
                    },
                    {
                      title: "Stay Physically Active",
                      text: "Exercise is a powerful way to reduce stress. Whether it's running, swimming, or even a 10-minute walk, movement releases endorphins that boost your mood.",
                    },
                    {
                      title: "Stay Connected",
                      text: "Don't isolate yourself. Share your worries with friends, family, or a campus counselor. Sometimes just talking about your stress can help relieve it.",
                    },
                    {
                      title: "Healthy Sleep Habits",
                      text: "Prioritize sleep. Lack of sleep only adds to stress, so aim for 7-8 hours a night.",
                    },
                    {
                      title: "Know When to Take a Break",
                      text: "If you feel overwhelmed, take a step back. A short break or relaxation activity can clear your mind, making you more productive.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "While stress is inevitable in university life, it doesn't have to take over your life. By adopting healthy coping strategies, you can manage stress and stay focused on enjoying your university experience.",
          },
        },
      },
    ],
  },
  {
    id: 4,
    category: "College Life",
    blogs: [
      {
        id: 401,
        title: "Overcoming Procrastination: A Guide for College Students",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["procrastination", "college life", "productivity"],
        image: "./img/procastination.jpg",
        summary:
          "Explore common causes of procrastination and effective strategies to overcome it, ensuring a more productive college experience.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "Procrastination is something almost every university student struggles with at some point. The thought of having a paper due or studying for an exam can often lead to endless hours of scrolling through social media or binge-watching shows. While it might feel good in the moment to avoid tasks, procrastination can lead to unnecessary stress, poor academic performance, and a lack of confidence.",
          },
          sections: [
            {
              id: 1,
              heading: "What is Procrastination?",
              content: [
                {
                  type: "paragraph",
                  text: "Procrastination is the act of delaying tasks or decisions. It often happens when you're faced with something that feels overwhelming or unpleasant. Instead of tackling the task head-on, you find yourself doing anything but the work that needs to be done. For many university students, procrastination becomes a habit, especially with academic tasks like studying or writing papers.",
                },
              ],
            },
            {
              id: 2,
              heading: "Why Do We Procrastinate?",
              content: [
                {
                  type: "paragraph",
                  text: "Understanding why you procrastinate is the first step to overcoming it. Common reasons include:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Fear of Failure",
                      text: "When you doubt your ability to succeed, it's easy to put off starting a task because of fear of doing it wrong.",
                    },
                    {
                      title: "Perfectionism",
                      text: "Wanting everything to be perfect can cause you to avoid tasks that you feel won't meet your high standards.",
                    },
                    {
                      title: "Lack of Motivation",
                      text: "If the task feels boring or too difficult, it can be hard to find the motivation to start.",
                    },
                    {
                      title: "Poor Time Management",
                      text: "Without a structured plan, it's easy to lose track of time or underestimate how long tasks will take.",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "The Impact of Procrastination",
              content: [
                {
                  type: "paragraph",
                  text: "Procrastination may provide temporary relief, but in the long run, it creates more stress. Here's how it can affect you:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Increased Stress",
                      text: "Leaving tasks until the last minute often leads to a frantic rush to finish, which causes anxiety and fatigue.",
                    },
                    {
                      title: "Lower Academic Performance",
                      text: "The quality of your work suffers when you don't give yourself enough time to complete assignments or study properly.",
                    },
                    {
                      title: "Guilt and Self-Doubt",
                      text: "Procrastination often leads to feelings of guilt, which can erode your self-confidence and make it harder to start tasks in the future.",
                    },
                  ],
                },
              ],
            },
            {
              id: 4,
              heading: "Strategies to Overcome Procrastination",
              content: [
                {
                  type: "paragraph",
                  text: "Procrastination is a habit, but it's one that can be broken. Here are some strategies to help you stay on track:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Break Tasks Into Smaller Steps",
                      text: "Large tasks can feel overwhelming, so break them down into smaller, more manageable parts. Completing even one small part can give you a sense of accomplishment and motivate you to keep going.",
                    },
                    {
                      title: "Set Specific Deadlines",
                      text: "Instead of thinking 'I'll get this done later,' set a specific time to start and finish tasks. Having a concrete deadline creates a sense of urgency.",
                    },
                    {
                      title: "Remove Distractions",
                      text: "Identify the things that distract you the most, like your phone or TV, and create a workspace where you can focus without interruptions.",
                    },
                    {
                      title: "Use the Pomodoro Technique",
                      text: "Work for 25 minutes, then take a 5-minute break. This technique keeps you focused while also giving you regular intervals to recharge.",
                    },
                    {
                      title: "Reward Yourself",
                      text: "After completing a task, give yourself a small reward — whether it's watching an episode of your favorite show or having a snack. This reinforces positive behavior.",
                    },
                    {
                      title: "Seek Accountability",
                      text: "Share your goals with a friend or study partner who can check in on your progress and keep you accountable.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "Procrastination is a common challenge, but it doesn't have to control your academic life. By understanding the reasons behind your procrastination and using these strategies, you can break the cycle and stay productive.",
          },
        },
      },
      {
        id: 402,
        title: "Dealing with Loneliness in College",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["mental health", "loneliness", "college life"],
        image: "./img/lonely.jpg",
        summary:
          "Explore the reasons for loneliness in college and discover effective strategies to cope and build meaningful connections.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "Starting college is an exciting time, but it can also be lonely. Many students struggle with feelings of isolation, especially during the first few months. Being away from home, adjusting to a new environment, and trying to make new friends can lead to loneliness. But it's important to know that loneliness is a common experience, and there are ways to cope with it.",
          },
          sections: [
            {
              id: 1,
              heading: "Why Do Students Feel Lonely?",
              content: [
                {
                  type: "paragraph",
                  text: "There are several reasons why students may feel lonely in university:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Homesickness",
                      text: "Being away from family and familiar surroundings can lead to feelings of loneliness, especially in the first year.",
                    },
                    {
                      title: "Social Pressures",
                      text: "Trying to fit in and make new friends can be challenging. Not finding your 'group' immediately may leave you feeling excluded.",
                    },
                    {
                      title: "Academic Stress",
                      text: "Long hours of studying or working on projects can isolate you from social activities.",
                    },
                    {
                      title: "Social Media Illusion",
                      text: "Seeing peers having fun online can make you feel like you're missing out, even if you're not.",
                    },
                  ],
                },
              ],
            },
            {
              id: 2,
              heading: "Symptoms of Loneliness",
              content: [
                {
                  type: "paragraph",
                  text: "Loneliness affects people in different ways. Some signs that you might be experiencing loneliness include:",
                },
                {
                  type: "list",
                  items: [
                    {
                      text: "Feeling sad or anxious",
                    },
                    {
                      text: "Difficulty sleeping or eating",
                    },
                    {
                      text: "Losing interest in socializing",
                    },
                    {
                      text: "Spending excessive time alone",
                    },
                    {
                      text: "Relying too much on social media for interaction",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "How to Cope with Loneliness",
              content: [
                {
                  type: "paragraph",
                  text: "Loneliness is not something you have to endure alone. There are plenty of ways to combat these feelings and build meaningful connections:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Join Campus Clubs or Activities",
                      text: "Universities offer a wide range of student groups and societies. Joining one that interests you is a great way to meet like-minded people.",
                    },
                    {
                      title: "Attend Social Events",
                      text: "Many universities host events specifically designed to help students meet new people. Don't be afraid to attend, even if you go alone.",
                    },
                    {
                      title: "Reach Out to Classmates",
                      text: "Initiating a conversation with someone in your class or dorm can be the first step toward building friendships.",
                    },
                    {
                      title: "Seek Professional Support",
                      text: "If loneliness persists, talking to a campus counselor can help. They can offer support and connect you with resources to manage your feelings.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "Loneliness is a common but temporary experience for many students. By actively seeking out social opportunities and being open to new experiences, you can build meaningful relationships and feel more connected to your university community.",
          },
        },
      },
      {
        id: 403,
        title: "Coping with Academic Burnout in College",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["burnout", "mental health", "college life"],
        image: "./img/people_pleasure.jpg",
        summary:
          "Learn about academic burnout, its signs and causes, and effective strategies to recover and regain motivation.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "College life is demanding, and it's easy to burn out when you're trying to balance lectures, assignments, exams, and your social life. Academic burnout happens when you feel physically and emotionally exhausted due to prolonged stress, leaving you feeling unmotivated, disengaged, and mentally drained.",
          },
          sections: [
            {
              id: 1,
              heading: "What is Academic Burnout?",
              content: [
                {
                  type: "paragraph",
                  text: "Burnout is a state of emotional, mental, and physical exhaustion caused by excessive stress. Academic burnout occurs when the pressures of university life become overwhelming, and you start feeling detached from your studies. It often leads to a decline in academic performance and mental health.",
                },
              ],
            },
            {
              id: 2,
              heading: "Signs and Symptoms of Academic Burnout",
              content: [
                {
                  type: "paragraph",
                  text: "Burnout isn't always easy to recognize. Here are some common signs:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Exhaustion",
                      text: "You feel tired all the time, even if you've had enough sleep.",
                    },
                    {
                      title: "Lack of Motivation",
                      text: "The excitement you once had for your courses has disappeared. You dread going to class or starting assignments.",
                    },
                    {
                      title: "Difficulty Concentrating",
                      text: "It becomes harder to focus on reading, writing, or studying, and you find yourself zoning out.",
                    },
                    {
                      title: "Procrastination",
                      text: "You put off tasks for as long as possible because you feel mentally drained.",
                    },
                    {
                      title: "Physical Symptoms",
                      text: "Burnout can cause headaches, stomach problems, or frequent colds due to a weakened immune system.",
                    },
                    {
                      title: "Increased Anxiety or Depression",
                      text: "If burnout is left untreated, it can lead to anxiety or depression, making it harder to enjoy university life.",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "Causes of Academic Burnout",
              content: [
                {
                  type: "paragraph",
                  text: "Several factors contribute to burnout in students:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Overload of Coursework",
                      text: "Too many assignments or exams in a short period can cause stress and fatigue.",
                    },
                    {
                      title: "Poor Time Management",
                      text: "When students don't have a proper schedule, they often overwork themselves at the last minute, leading to burnout.",
                    },
                    {
                      title: "Perfectionism",
                      text: "Striving for perfect grades can lead to overworking and constant stress.",
                    },
                    {
                      title: "Lack of Breaks",
                      text: "Many students don't take time off to relax, leading to constant stress and no downtime for recovery.",
                    },
                  ],
                },
              ],
            },
            {
              id: 4,
              heading: "How to Recover from Academic Burnout",
              content: [
                {
                  type: "paragraph",
                  text: "If you're experiencing burnout, it's important to take steps to recover before it affects your mental health and academic success. Here's how:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Take Breaks",
                      text: "Schedule regular breaks during study sessions to give your brain time to rest. Whether it's going for a walk or taking a nap, downtime is essential for avoiding burnout.",
                    },
                    {
                      title: "Prioritize Self-Care",
                      text: "Make time for activities that help you relax, such as yoga, meditation, or simply spending time with friends.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "Recognizing academic burnout early and taking proactive steps can help you maintain your mental health and academic performance. By prioritizing self-care and managing stress, you can find a balance that supports your success in college.",
          },
        },
      },
      {
        id: 404,
        title: "Understanding and Managing Exam Anxiety",
        author: "Dr. Rajvardhan Bhanwar",
        publishedDate: "October 2024",
        tags: ["exam anxiety", "mental health", "college life"],
        image: "./img/exams.jpg",
        summary:
          "Explore the causes and symptoms of exam anxiety, and discover effective strategies for managing it to improve your performance during exams.",
        content: {
          introduction: {
            type: "paragraph",
            content:
              "Exams are one of the most stressful times in college life. While a little nervousness before an exam is normal and can even improve performance, for some students, this anxiety can become overwhelming. Exam anxiety can make it difficult to concentrate, remember information, and perform to the best of your ability.",
          },
          sections: [
            {
              id: 1,
              heading: "What is Exam Anxiety?",
              content: [
                {
                  type: "paragraph",
                  text: "Exam anxiety is a type of performance anxiety where students experience intense fear, stress, or nervousness about taking exams. This anxiety can affect your ability to think clearly, recall information, and focus during an exam. It may also cause physical symptoms like sweating, shaking, or nausea.",
                },
              ],
            },
            {
              id: 2,
              heading: "Common Symptoms of Exam Anxiety",
              content: [
                {
                  type: "paragraph",
                  text: "Some common symptoms of exam anxiety include:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Physical Symptoms",
                      text: "Fast heartbeat, shortness of breath, nausea, headaches, or shaking.",
                    },
                    {
                      title: "Cognitive Symptoms",
                      text: "Racing thoughts, difficulty concentrating, or blanking out on information during the exam.",
                    },
                    {
                      title: "Emotional Symptoms",
                      text: "Feelings of fear, dread, or hopelessness about the exam. You might also feel overwhelmed by thoughts of failure.",
                    },
                    {
                      title: "Behavioral Symptoms",
                      text: "Avoiding studying, procrastinating, or feeling the need to constantly review material without retaining it.",
                    },
                  ],
                },
              ],
            },
            {
              id: 3,
              heading: "Causes of Exam Anxiety",
              content: [
                {
                  type: "paragraph",
                  text: "Several factors can contribute to exam anxiety:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Fear of Failure",
                      text: "Worrying about what will happen if you don't do well on the exam can increase anxiety.",
                    },
                    {
                      title: "Lack of Preparation",
                      text: "Feeling unprepared can heighten stress, as can cramming the night before.",
                    },
                    {
                      title: "High Pressure",
                      text: "The pressure to perform well, especially if you're aiming for top grades or scholarships, can contribute to anxiety.",
                    },
                    {
                      title: "Negative Self-Talk",
                      text: "Telling yourself that you're going to fail or that you're not smart enough can worsen anxiety.",
                    },
                  ],
                },
              ],
            },
            {
              id: 4,
              heading: "Strategies to Manage Exam Anxiety",
              content: [
                {
                  type: "paragraph",
                  text: "While exam anxiety is common, there are several strategies to manage it and perform better during exams:",
                },
                {
                  type: "list",
                  items: [
                    {
                      title: "Prepare Early",
                      text: "Don't leave studying until the last minute. Break your study sessions into manageable chunks over weeks, and review material regularly.",
                    },
                    {
                      title: "Practice Relaxation Techniques",
                      text: "Before and during exams, try deep breathing exercises or progressive muscle relaxation to calm your nerves.",
                    },
                    {
                      title: "Stay Organized",
                      text: "Have a clear study plan and a to-do list for each day. Knowing what you need to do can reduce anxiety.",
                    },
                    {
                      title: "Get Enough Sleep",
                      text: "Pulling all-nighters can worsen anxiety and reduce your ability to concentrate. Aim for 7-8 hours of sleep, especially the night before an exam.",
                    },
                    {
                      title: "Challenge Negative Thoughts",
                      text: "Replace negative self-talk with more positive and realistic thoughts, like 'I've prepared for this, and I'll do my best.'",
                    },
                    {
                      title: "Use Visualization",
                      text: "Picture yourself successfully completing the exam. Visualizing success can help reduce fear and increase confidence.",
                    },
                  ],
                },
              ],
            },
          ],
          conclusion: {
            type: "paragraph",
            content:
              "Exam anxiety is a common but manageable problem. By preparing in advance, staying organized, and practicing relaxation techniques, you can reduce anxiety and improve your performance during exams. Remember, one exam doesn't define your entire academic career, and doing your best is all that truly matters.",
          },
        },
      },
    ],
  },
];

export { blogData };
