import React, { useState } from "react";
import { blogData } from "./blogData";
import Header from "../Home/Header";
import { Link, useNavigate } from "react-router-dom";

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const getAllBlogs = () => {
    return blogData.reduce((allblogs, categoryData) => {
      const blogsWithCategory = categoryData.blogs.map((blog) => ({
        ...blog,
        category: categoryData.category,
      }));
      return allblogs.concat(blogsWithCategory);
    }, []);
  };

  const selectedBlogs =
    selectedCategory === "all"
      ? getAllBlogs()
      : blogData
          .find((categoryData) => categoryData.category === selectedCategory)
          ?.blogs.map((blog) => ({
            ...blog,
            category: selectedCategory,
          })) || [];

  return (
    <>
      <Header />
      <div className="py-16 lg:py-28 px-4 sm:px-40 font-montserrat min-h-svh sm:min-h-screen bg-blue-50">
        <div className="flex flex-wrap justify-center my-8 gap-y-2 text-xs sm:text-base">
          <button
            key="all"
            className={`mx-2 px-6 py-2 font-medium ${
              selectedCategory === "all"
                ? "border-b-2 font-semibold border-blue-600"
                : "border-gray-600 text-gray-600"
            }`}
            onClick={() => handleCategoryClick("all")}
          >
            All
          </button>
          {blogData.map((categoryData, index) => (
            <button
              key={index}
              className={`mx-2 px-6 py-2 font-medium ${
                selectedCategory === categoryData.category
                  ? "border-b-2 font-semibold border-blue-600"
                  : "border-gray-600 text-gray-600"
              }`}
              onClick={() => handleCategoryClick(categoryData.category)}
            >
              {categoryData.category}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {selectedBlogs.map((blog, idx) => (
            <Link
              key={idx}
              to={`/blogs/${blog.category
                .replace(/\s+/g, "-")
                .toLowerCase()}/${blog.title
                .replace(/\s+/g, "-")
                .toLowerCase()}`}
              className="p-4 group rounded-lg shadow-md hover:shadow-xl cursor-pointer h-80 relative overflow-hidden bg-cover bg-center"
              style={{ backgroundImage: `url(${blog.image})` }}
            >
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50"></div>
              <p className="absolute top-2 right-2 text-xs text-white px-2 py-1 bg-gray-800 font-medium rounded-md w-fit">
                {blog.category}
              </p>
              <div className="absolute group-hover:bg-gradient-to-b group-hover:from-transparent group-hover:to-black inset-0 bg-gradient-to-b from-transparent via-transparent to-black flex flex-col justify-end p-4 text-white">
                <h3 className="font-semibold mb-1">
                  {blog.title || "Untitled Blog"}
                </h3>
                <p className="text-xs overflow-hidden transition-all duration-500 ease-in-out opacity-70 max-h-4 group-hover:max-h-20 group-hover:opacity-100">
                  {blog.summary}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blog;
