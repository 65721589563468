import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-800 text-gray-200 p-4 font-montserrat">
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 max-w-md text-center ">
        <FaExclamationTriangle className="text-red-500 text-6xl mb-4 mx-auto" />
        <p className="text-xl mb-2">Something went wrong.</p>
        <p className="text-lg mb-4">
          {error.status ? (
            <>
              Error {error.status}: {error.statusText}
            </>
          ) : (
            "An unexpected error occurred."
          )}
        </p>
        <p className="mb-6">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <button
          onClick={handleGoBack}
          className="inline-block bg-red-600 text-white font-semibold py-2 px-4 rounded transition duration-200 hover:bg-red-700"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
