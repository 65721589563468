import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { blogData } from "./blogData";
import Header from "../Home/Header";
import { MdCategory } from "react-icons/md";

const BlogDetail = () => {
  const { category, blogTitle } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedBlog = blogData
    .find(
      (categoryData) =>
        categoryData.category.replace(/\s+/g, "-").toLowerCase() === category
    )
    ?.blogs.find(
      (blog) => blog.title.replace(/\s+/g, "-").toLowerCase() === blogTitle
    );

  if (!selectedBlog) {
    return <div className="text-center text-xl mt-20">Blog not found</div>;
  }

  return (
    <>
      <Header />
      <div className="pt-32 p-6 lg:px-52 rounded-lg shadow-lg text-gray-700 flex flex-col items-start font-montserrat ">
        <h2 className="text-3xl md:text-4xl font-bold mb-4">
          {selectedBlog.title}
        </h2>

        <div className="text-sm text-gray-500 flex gap-x-6 flex-col md:flex-row md:items-center border-b w-full pb-4 mb-6 border-gray-300">
          <p className="capitalize mb-2 md:mb-0">
            Category: {category.replace(/-/g, " ")}
          </p>
          <p>{`By ${selectedBlog.author} - ${selectedBlog.publishedDate}`}</p>
        </div>

        <div className="space-y-6">
          {selectedBlog.content.introduction.type === "paragraph" && (
            <p className="text-lg font-medium leading-relaxed bg-gray-100 p-6 rounded-md ">
              {selectedBlog.content.introduction.content}
            </p>
          )}

          {selectedBlog.content.sections.map((section, index) => (
            <div key={index} className="rounded-lg p-6 bg-gray-100">
              <h2 className="text-2xl font-bold mb-1">{section.heading}</h2>
              {section.content.map((contentItem, contentIndex) => {
                if (contentItem.type === "paragraph") {
                  return (
                    <p
                      key={contentIndex}
                      className="text-base py-1 font-medium leading-relaxed"
                    >
                      {contentItem.text}
                    </p>
                  );
                } else if (contentItem.type === "list") {
                  return (
                    <ul
                      key={contentIndex}
                      className="list-disc list-inside pl-5 py-1"
                    >
                      {contentItem.items.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className="text-base font-medium leading-relaxed"
                        >
                          <span className="font-semibold">
                            {item.title && item.title + `:`}
                          </span>{" "}
                          {item.text}
                        </li>
                      ))}
                    </ul>
                  );
                }
                return null;
              })}
            </div>
          ))}

          {selectedBlog.content.conclusion.type === "paragraph" && (
            <p className="text-lg leading-relaxed font-semibold bg-gray-100 p-6 rounded-md ">
              {selectedBlog.content.conclusion.content}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
