import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { userContext } from "./context";
import { ColorRing } from "react-loader-spinner";
function Protected(props) {
  const { Component } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { user } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchUserData() {
    try {
      const token = localStorage.getItem("token");
      if (!token) return null;

      const response = await axios.get(
        `https://manthanr.onrender.com/v1/get-user-info/${user.userID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const profileComplete = response.data.is_profile_complete;
      localStorage.setItem(
        "isProfileComplete",
        JSON.stringify(profileComplete)
      );

      return profileComplete;
    } catch (error) {
      console.error("Error fetching user info:", error);
      return null;
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const adminToken = localStorage.getItem("adminToken");
    const superadminToken = localStorage.getItem("superadminToken");
    console.log("path", pathname.toLowerCase());
    if (adminToken && pathname.toLowerCase() !== "/admindashboard") {
      navigate("/adminDashboard");
      return;
    } else if (
      superadminToken &&
      pathname.toLowerCase() !== "/superadmindashboard"
    ) {
      navigate("/SuperAdminDashboard");
      return;
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    async function checkProfileStatus() {
      let profileStatus = JSON.parse(localStorage.getItem("isProfileComplete"));

      if (profileStatus === null || profileStatus === undefined) {
        profileStatus = await fetchUserData();
      }

      const normalizedPathname = pathname.toLowerCase();

      if (token && !profileStatus) {
        navigate("/UpdateProfile");
      } else if (profileStatus && normalizedPathname === "/updateprofile") {
        navigate("/usersection");
      } else if (token && normalizedPathname === "/adminlogin") {
        navigate("/usersection");
      }
    }

    checkProfileStatus();
    return () => {
      clearTimeout(timer);
    };
  }, [navigate, pathname, user.userID]);

  if (isLoading) {
    return (
      <div className="w-full h-screen flex flex-col items-center font-montserrat justify-center">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        />

        <p className="mt-2 text-xl font-medium text-gray-600">Loading...</p>
      </div>
    );
  }

  return <Component />;
}

export default Protected;
