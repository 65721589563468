import { useState } from "react";
import { superadminContext, userContext, adminContext } from "./context";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./components/Auth/Login";
import "./App.css";
import UpdateProfile from "./components/User/UpdateProfile";
import Chatbot from "./components/User/Chatbot";
import MainPage from "./components/Home/MainPage";
import AdminLogin from "./components/Auth/AdminLogin";
import { authContext } from "./context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./components/Admin/AdminDashboard";
import SuperAdminDashboard from "./components/SuperAdmin/SuperAdminDashboard";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Disclaimer from "./components/Home/Disclaimer";
import UserSection from "./components/User/UserSection";
import { loadingContext } from "./context";
import { adminEmailContext } from "./context";
import EditProfileForm from "./components/User/edit_profile";
import EventsPage from "./components/Home/EventsPage";
import SessionManager from "./SessionManager";
import Protected from "./Protected";
import Consult from "./components/Home/Consult";
import QuizPage from "./components/Quiz/QuizPage";
import QuizPlayground from "./components/Quiz/QuizPlayground";
import Blogs from "./components/Blog/Blogs";
import BlogDetail from "./components/Blog/BlogDetail";
import ErrorPage from "./components/Home/ErrorPage";

function App() {
  const [user, setUser] = useState({
    username: "",
    userID: "",
    email: "",
    assigned_admin: "",
    assigned_admin_id: "",
  });
  const [auth, setAuth] = useState(false);
  const [superadmin, setsuperadmin] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    username: "",
    adminID: "",
    email: "",
  });
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/updateprofile",
      element: <Protected Component={UpdateProfile} />,
    },
    {
      path: "/chatbot",
      element: <Protected Component={Chatbot} />,
    },
    {
      path: "/edit-profile",
      element: <Protected Component={EditProfileForm} />,
    },

    {
      path: "/adminlogin",
      element: <AdminLogin />,
    },
    {
      path: "/disclaimer",
      element: <Protected Component={Disclaimer} />,
    },
    {
      path: "/admindashboard",
      element: <Protected Component={AdminDashboard} />,
    },
    {
      path: "/SuperAdminDashboard",
      element: <Protected Component={SuperAdminDashboard} />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/usersection",
      element: <Protected Component={UserSection} />,
    },
    {
      path: "/upcoming-events",
      element: <EventsPage />,
    },
    {
      path: "/consult",
      element: <Consult />,
    },
    {
      path: "/quizzes",
      element: <QuizPage />,
    },
    {
      path: "/quizzes/:quizTitle",
      element: <QuizPlayground />,
    },
    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/blogs/:category/:blogTitle",
      element: <BlogDetail />,
    },
  ]);
  return (
    <>
      <SessionManager />
      <loadingContext.Provider value={{ loading, setLoading }}>
        <userContext.Provider value={{ user, setUser }}>
          <superadminContext.Provider value={{ superadmin, setsuperadmin }}>
            <adminEmailContext.Provider value={{ adminEmail, setAdminEmail }}>
              <adminContext.Provider value={{ admin, setAdmin }}>
                <authContext.Provider value={{ auth, setAuth }}>
                  <RouterProvider router={router} />
                  <ToastContainer />
                </authContext.Provider>
              </adminContext.Provider>
            </adminEmailContext.Provider>
          </superadminContext.Provider>
        </userContext.Provider>
      </loadingContext.Provider>
    </>
  );
}

export default App;
