import React from "react";
import { Link } from "react-router-dom";

const QuizCard = ({ quiz }) => {
  return (
    <div
      className="p-4 min-h-[450px] group rounded-lg shadow-md hover:shadow-xl relative overflow-hidden bg-cover bg-center"
      style={{ backgroundImage: `url(${quiz.image})` }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black md:opacity-80 md:group-hover:opacity-50"></div>
      <div className="absolute md:group-hover:bg-gradient-to-b md:group-hover:from-transparent md:group-hover:to-black inset-0 bg-gradient-to-b from-transparent via-transparent to-black flex flex-col justify-end p-4 text-white">
        <h3 className="font-semibold text-2xl group-hover:translate-y-1">
          {quiz.name}
        </h3>
        <p className="text-white text-sm mt-2">
          {quiz.shortInfo}
        </p>
        <Link
          to={`${quiz.name.replace(/\s+/g, "-").toLowerCase()}`}
          className="mt-4 text-center cursor-pointer bg-blue-500 hover:bg-blue-600 w-full p-2 text-white rounded-lg font-semibold"
        >
          Start Quiz
        </Link>
      </div>
    </div>
  );
};

export default QuizCard;
