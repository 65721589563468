const QuizData = {
  quizzes: [
    {
      id: "depression1",
      name: "Depression Quiz",
      image: "/img/quiz1.jpg",
      shortInfo:
        "Assess your emotional health and gain insights to improve your well-being.",
      description:
        "Take this quiz to evaluate your feelings and behaviors related to depression. By identifying potential symptoms, you can better understand your emotional state and explore support options that may enhance your well-being.",
      estimatedTime: "3 minutes",
      questions: [
        {
          question:
            "Are you facing trouble concentrating on things, such as reading books or remembering things?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question:
            "Has there been a change in your appetite - are you eating too much or too less?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question: "Have you been feeling tired or facing a lack of energy?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question:
            "Are you having problems with sleep - trouble falling asleep, or staying asleep, or sleeping a lot?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question:
            "Have you been fidgety or restless and moving too much or the opposite, moving or speaking too slowly?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question: "Has your interest or pleasure in doing things reduced?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question:
            "Have you been feeling down, having a low mood, or feeling hopeless?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question:
            "Have you feeling bad about yourself or that you are a failure or have let yourself or your family down?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
        {
          question:
            "Have you ever thought of hurting yourself or thought that you would be better off dead?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Sometimes", score: 1 },
            { text: "More than half of time", score: 2 },
            { text: "Almost always", score: 3 },
          ],
        },
      ],
      scoring: {
        "0-4": {
          title: "No Depression",
          description:
            "Based on your responses, you are not showing significant signs of depression. It's great that you're feeling mentally well!",
          tips: [
            "If you ever feel overwhelmed or notice changes in your mental health, don't hesitate to reach out for support.",
            "Maintaining a healthy lifestyle, including regular exercise, good sleep, and a balanced diet, can help keep you on track.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/overcoming-procrastination:-a-guide-for-college-students",
              text: "Overcoming Procrastination: A Guide for College Students",
            },
          },
        },
        "5-9": {
          title: "Mild Depression",
          description:
            "Your score indicates mild symptoms of depression. This might mean you're experiencing occasional low mood or lack of motivation, but these symptoms may not be affecting your daily life significantly.",
          tips: [
            "Keep monitoring your feelings, and consider making small changes like regular physical activity, mindfulness practices, or talking to a friend.",
            "If these symptoms persist, it may be helpful to seek guidance from a mental health professional for early support.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/overcoming-procrastination:-a-guide-for-college-students",
              text: "Overcoming Procrastination: A Guide for College Students",
            },
          },
        },
        "10-14": {
          title: "Moderate Depression",
          description:
            "Your score suggests moderate symptoms of depression. These feelings might be affecting your ability to function in certain areas of life.",
          tips: [
            "It's important to take action now to prevent these symptoms from worsening.",
            "We recommend scheduling a consultation with a psychiatrist or mental health professional who can help you explore treatment options such as therapy or lifestyle adjustments. Early intervention can make a significant difference.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/overcoming-procrastination:-a-guide-for-college-students",
              text: "Overcoming Procrastination: A Guide for College Students",
            },
          },
        },
        "15-19": {
          title: "Moderately Severe Depression",
          description:
            "Your score indicates moderately severe symptoms of depression. It is likely that these feelings are making everyday tasks challenging and reducing your overall quality of life.",
          tips: [
            "Seeking professional support is highly recommended at this stage.",
            "Treatment such as Cognitive Behavioral Therapy (CBT), medication, or a combination of both can help you manage these symptoms effectively.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/overcoming-procrastination:-a-guide-for-college-students",
              text: "Overcoming Procrastination: A Guide for College Students",
            },
          },
        },
        "20-27": {
          title: "Severe Depression",
          description: "Your responses indicate severe symptoms of depression.",
          tips: [
            "It's essential that you seek professional help immediately.",
            "Treatment options such as therapy and antidepressants can significantly improve your condition.",
            "A psychiatrist can offer personalized guidance to help you regain control of your mental health.",
            "Remember, help is available, and taking the first step by seeking support is vital.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/overcoming-procrastination:-a-guide-for-college-students",
              text: "Overcoming Procrastination: A Guide for College Students",
            },
          },
        },
      },
    },
    {
      id: "anxiety1",
      name: "Anxiety Quiz",
      image: "/img/quiz2.jpg",
      shortInfo:
        "Evaluate your anxiety levels and discover effective coping strategies.",
      description:
        "Take this quiz to assess your current feelings of anxiety. By understanding your anxiety symptoms, you can identify areas for improvement and explore practical strategies to enhance your emotional well-being.",
      estimatedTime: "2 minutes",
      questions: [
        {
          question: "Have you been feeling very nervous or on edge?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Several days", score: 1 },
            { text: "More than half the days", score: 2 },
            { text: "Nearly every day", score: 3 },
          ],
        },
        {
          question: "Have you been able to stop or control your worries?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Several days", score: 1 },
            { text: "More than half the days", score: 2 },
            { text: "Nearly every day", score: 3 },
          ],
        },
        {
          question:
            "Have you been worrying too much about different things or feeling as if something bad is going to happen?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Several days", score: 1 },
            { text: "More than half the days", score: 2 },
            { text: "Nearly every day", score: 3 },
          ],
        },
        {
          question:
            "Have you been facing trouble relaxing or finding it hard to sit still?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Several days", score: 1 },
            { text: "More than half the days", score: 2 },
            { text: "Nearly every day", score: 3 },
          ],
        },
        {
          question: "Have you been irritable or easily annoyed?",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Several days", score: 1 },
            { text: "More than half the days", score: 2 },
            { text: "Nearly every day", score: 3 },
          ],
        },
        {
          question: "Becoming easily annoyed or irritable",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Several days", score: 1 },
            { text: "More than half the days", score: 2 },
            { text: "Nearly every day", score: 3 },
          ],
        },
        {
          question: "Feeling afraid, as if something awful might happen",
          options: [
            { text: "Not at all", score: 0 },
            { text: "Several days", score: 1 },
            { text: "More than half the days", score: 2 },
            { text: "Nearly every day", score: 3 },
          ],
        },
      ],
      scoring: {
        "0-4": {
          title: "Minimal Anxiety",
          description:
            "Your responses suggest minimal or no significant symptoms of anxiety. This is a positive sign, and it's great that anxiety is not affecting your daily life.",
          tips: [
            "Keep practicing healthy habits like regular exercise, a balanced diet, and stress management techniques to maintain your well-being.",
            "Should you ever feel an increase in anxiety, don't hesitate to seek support or revisit these self-assessments.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/anxiety/dealing-with-social-anxiety",
              text: "Dealing with Social Anxiety",
            },
          },
        },
        "5-9": {
          title: "Mild Anxiety",
          description:
            "Your score indicates mild symptoms of anxiety. You may notice occasional feelings of worry, restlessness, or nervousness, but they likely aren't severely impacting your daily life.",
          tips: [
            "It's important to keep an eye on these feelings and adopt stress-reducing activities like mindfulness, relaxation techniques, or physical activity.",
            "If these symptoms persist or worsen, consider seeking advice from a mental health professional to manage them early on.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/anxiety/dealing-with-social-anxiety",
              text: "Dealing with Social Anxiety",
            },
          },
        },
        "10-14": {
          title: "Moderate Anxiety",
          description:
            "Your responses suggest moderate anxiety. These feelings of worry and tension may be affecting your ability to concentrate, sleep, or complete daily tasks.",
          tips: [
            "It's a good time to seek help from a mental health professional who can guide you through strategies like Cognitive Behavioral Therapy (CBT), relaxation exercises, or lifestyle changes.",
            "Early intervention can prevent these symptoms from becoming more severe.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/anxiety/managing-anxiety",
              text: "Managing Anxiety",
            },
          },
        },
        "15-21": {
          title: "Severe Anxiety",
          description:
            "Your score indicates severe anxiety, which may be significantly impacting your quality of life. These symptoms could include constant worry, physical symptoms like headaches or heart palpitations, and difficulty managing everyday activities.",
          tips: [
            "It is highly recommended that you consult a psychiatrist or therapist as soon as possible.",
            "Treatment options, including therapy and possibly medication, can help you regain control of your anxiety and improve your overall well-being.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/anxiety/managing-anxiety",
              text: "Managing Anxiety",
            },
          },
        },
      },
    },
    {
      id: "perfectionism1",
      name: "Perfectionism Test",
      image: "/img/quiz3.jpg",
      shortInfo:
        "Evaluate how perfectionism influences your daily life and well-being.",
      description:
        "Take this test to explore how perfectionism affects you, helping you find areas for growth and build healthier goal-setting habits.",
      estimatedTime: "5 minutes",
      questions: [
        {
          question: "I often think that I should've done better than I did.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question:
            "I tend to put things off if I don't have the time to do them perfectly.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I'm afraid to fail when working on an important project.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question:
            "I strive to impress others with my best qualities or accomplishments.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I think less of myself if I repeat a mistake.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I strive to maintain control of my emotions at all times.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I get upset when things don't go as planned.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question:
            "I am often disappointed in the quality of other people's work.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I feel that my standards couldn't be too high.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I'm afraid that people will think less of me if I fail.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I'm constantly trying to improve myself.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I'm unhappy if anything I do is considered average.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "My home and office need to be clean and orderly always.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question:
            "I feel inferior to others who are more intelligent, attractive, or successful than I.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
        {
          question: "I must look my very best whenever I'm out in public.",
          options: [
            { text: "Yes", score: 1 },
            { text: "No", score: 0 },
          ],
        },
      ],
      scoring: {
        "0-4": {
          title: "No Significant Perfectionism Issues",
          description:
            "Your responses suggest that perfectionism is not a significant issue for you. You seem to maintain a healthy balance and have realistic expectations for yourself and others.",
          tips: [
            "Continue practicing self-acceptance and be mindful of your achievements without overly high standards.",
            "Maintaining flexibility and adaptability can help sustain this positive outlook.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/anxiety/managing-anxiety",
              text: "Managing Anxiety",
            },
          },
        },
        "5-15": {
          title: "Potential Problem with Perfectionism",
          description:
            " Your score indicates a tendency toward perfectionism. This might mean you set very high standards for yourself or others, and you may experience stress, frustration, or dissatisfaction when these standards aren't met.",
          tips: [
            "It is important to recognize when your standards are becoming unrealistic or harmful. Consider setting more achievable goals and learning to appreciate your progress.",
            "Practices such as mindfulness, self-compassion, and cognitive behavioral strategies can help manage these tendencies.",
            "If perfectionism is causing significant distress or interfering with your daily life, seeking support from a mental health professional might be beneficial.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/anxiety/managing-anxiety",
              text: "Managing Anxiety",
            },
          },
        },
      },
    },
    {
      id: "alcohol1",
      name: "Alcohol Risk Assessment",
      image: "./img/alcohol.jpg",
      shortInfo:
        "Evaluate your alcohol consumption to identify potential risks and drinking patterns.",
      description:
        "This Alcohol Risk Assessment helps you understand your alcohol use patterns, providing insights into possible health risks and benefits of moderation.",
      estimatedTime: "4 minutes",
      questions: [
        {
          question: "How often do you have a drink containing alcohol?",
          options: [
            { text: "Never", score: 0 },
            { text: "Monthly or less", score: 1 },
            { text: "2-4 times a month", score: 2 },
            { text: "2-3 times a week", score: 3 },
            { text: "4 or more times a week", score: 4 },
          ],
        },
        {
          question:
            "How many drinks containing alcohol do you have on a typical day when you are drinking?",
          options: [
            { text: "1 or 2", score: 0 },
            { text: "3 or 4", score: 1 },
            { text: "5 or 6", score: 2 },
            { text: "7 or 9", score: 3 },
            { text: "10 or more", score: 4 },
          ],
        },
        {
          question: "How often do you have six or more drinks on one occasion?",
          options: [
            { text: "Never", score: 0 },
            { text: "Less than monthly", score: 1 },
            { text: "Monthly", score: 2 },
            { text: "Weekly", score: 3 },
            { text: "Daily or almost daily", score: 4 },
          ],
        },
        {
          question:
            "How often during the last year have you found that you were not able to top drinking once you had started?",
          options: [
            { text: "Never", score: 0 },
            { text: "Less than monthly", score: 1 },
            { text: "Monthly", score: 2 },
            { text: "Weekly", score: 3 },
            { text: "Daily or almost daily", score: 4 },
          ],
        },
        {
          question:
            "How often during the last year have you failed to do what was normally expected of you because of drinking?",
          options: [
            { text: "Never", score: 0 },
            { text: "Less than monthly", score: 1 },
            { text: "Monthly", score: 2 },
            { text: "Weekly", score: 3 },
            { text: "Daily or almost daily", score: 4 },
          ],
        },
        {
          question:
            "How often during the last year have you needed a first drink in the morning to get yourself going after a heavy drinking session?",
          options: [
            { text: "Never", score: 0 },
            { text: "Less than monthly", score: 1 },
            { text: "Monthly", score: 2 },
            { text: "Weekly", score: 3 },
            { text: "Daily or almost daily", score: 4 },
          ],
        },
        {
          question:
            "How often during the last year have you had a feeling of guilt or remorse after drinking?",
          options: [
            { text: "Never", score: 0 },
            { text: "Less than monthly", score: 1 },
            { text: "Monthly", score: 2 },
            { text: "Weekly", score: 3 },
            { text: "Daily or almost daily", score: 4 },
          ],
        },
        {
          question:
            "How often during the last year have you been unable to remember what happened the night before because of your drinking?",
          options: [
            { text: "Never", score: 0 },
            { text: "Less than monthly", score: 1 },
            { text: "Monthly", score: 2 },
            { text: "Weekly", score: 3 },
            { text: "Daily or almost daily", score: 4 },
          ],
        },
        {
          question:
            "Have you or someone else been injured because of your drinking?",
          options: [
            { text: "No", score: 0 },
            { text: "Yes, but not in the last year", score: 2 },
            { text: "Yes, during the last year", score: 4 },
          ],
        },
        {
          question:
            "Has a relative, friend, doctor or other health care worker been concerned about your drinking or suggested you cut down?",
          options: [
            { text: "No", score: 0 },
            { text: "Yes, but not in the last year", score: 2 },
            { text: "Yes, during the last year", score: 4 },
          ],
        },
      ],
      scoring: {
        "0-0": {
          title: "Abstainer",
          description:
            "Your score indicates that you do not consume alcohol, or your alcohol use has not led to any problems. This is a healthy and low-risk lifestyle choice.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/coping-with-academic-burnout-in-college",
              text: "Coping with Academic Burnout in College",
            },
          },
        },
        "1-7": {
          title: "Low-Risk Consumption",
          description:
            "Your score suggests that your alcohol consumption is within low-risk levels according to WHO guidelines. It is unlikely to cause harm if maintained within these limits.",
          tips: [
            "Continue to be mindful of your drinking habits.",
            "Stay within recommended guidelines to avoid potential health issues.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/coping-with-academic-burnout-in-college",
              text: "Coping with Academic Burnout in College",
            },
          },
        },
        "8-14": {
          title: "Hazardous Consumption",
          description:
            "Your score suggests hazardous or harmful drinking patterns. You may be at risk of developing health or social problems due to your alcohol use.",
          tips: [
            "Consider reducing your alcohol intake to lower your risk of harm.",
            "Seek support if you find it difficult to cut down on drinking.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/coping-with-academic-burnout-in-college",
              text: "Coping with Academic Burnout in College",
            },
          },
        },
        "15-40": {
          title: "Alcohol Dependence Likely",
          description:
            "Your score indicates a high likelihood of alcohol dependence (moderate-severe alcohol use disorder). It is important to seek help from a healthcare professional to address this.",
          tips: [
            "Reach out to a healthcare provider or support group for assistance.",
            "Consider making a plan to reduce or stop drinking with professional support.",
          ],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/college-life/coping-with-academic-burnout-in-college",
              text: "Coping with Academic Burnout in College",
            },
          },
        },
      },
    },
    {
      id: "anger1",
      name: "Anger Scale",
      image: "./img/angry.jpg",
      shortInfo:
        "Assess your anger levels and triggers to enhance emotional regulation.",
      description:
        "The Anger Scale quiz helps you identify what triggers your anger and the intensity of your reactions. By understanding your emotional responses, you can work towards better emotional control and a more peaceful mindset.",
      estimatedTime: "10 minutes",
      questions: [
        {
          question:
            "You unpack an appliance that you just bought, plug it in and discover that it doesn’t work",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "Being overcharged by a repairman who helped you out of a bind",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "Being singled out for correction when others go unnoticed",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "Getting your car stuck in the mud or snow",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "You are talking to someone and they don’t answer",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "Someone pretends to be something you're not",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "While you are struggling to carry four cups of coffee to your table at the cafeteria, someone bumps into you, spilling the coffee",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You hung up your coat but someone knocks it to the floor and doesn’t pick it up",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You are hounded by salesperson from the moment you walk in the store",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You made plans to go somewhere with a friend who backs out at the last minute leaving you hanging",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "Being joked about or teased",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You accidentally make a wrong turn in the parking lot. As you get out of your car someone yells at saying “Where did you learn how to drive”?",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "Your car stalls at a traffic light and the guy behind you keeps blowing his horn",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You are trying to concentrate but a person near you is tapping their foot",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "Someone makes a mistake and blames it on you",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You lend someone an important book or tool and they don’t return it",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You have had a busy day, and your roommate or spouse starts complaining about how you forgot to stop at the store",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You are trying to discuss something important with a friend or relative who isn’t giving you a chance to express your feelings",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You are in a discussion with someone who persists in arguing about a topic they know very little about",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "Someone sticks his/her nose into an argument between you and another person",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You’re already late and the car in front of you is going 25 mph in a 40 mph zone and you can’t pass",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "You step on a glob of chewing gum",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question: "You’re mocked by a small group of people as you pass them",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "In a hurry to get somewhere, you tear your favorite pair of pants",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
        {
          question:
            "You use your last quarter to make a phone call, but you are disconnected before you finish dialing and the quarter is not returned",
          options: [
            { text: "You would feel little or no annoyance", score: 0 },
            { text: "You would feel a little irritated", score: 1 },
            { text: "You would feel moderately upset", score: 2 },
            { text: "You feel quite angry", score: 3 },
            { text: "You would feel very angry", score: 4 },
          ],
        },
      ],

      scoring: {
        "0-45": {
          title: "Remarkably Low Anger",
          description:
            "The amount of anger and frustration you generally experience is remarkably low. Only a small percentage of the population will score this low on a test. You might want to examine whether you were honest with your answers and the possibility that you deny angry feelings.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
        "46-55": {
          title: "Substantially Peaceful",
          description:
            "You are substantially more peaceful than the average person.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
        "56-75": {
          title: "Average Anger Response",
          description:
            "You respond to life’s annoyances with an average amount of anger.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
        "76-85": {
          title: "Frequent Angry Reactions",
          description:
            "You frequently react in an angry way to life’s many frustrations. You are substantially more irritable than the average person.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
        "86-100": {
          title: "Frequent Intense Furious Reactions",
          description:
            "You are plagued by frequent intense furious reactions that do not quickly disappear. You probably harbor negative feelings long after the initial insult has passed. You may experience frequent tension headaches and elevated blood pressure. Your anger may often get out of control and lead to impulsive hostile outbursts, which at times get you into trouble.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
      },
    },
    {
      id: "distress1",
      name: "Distress Scale",
      image: "./img/distress.jpg",
      shortInfo:
        "A self-assessment tool for evaluating emotional well-being over the past 30 days.",
      description:
        "Answer 10 questions about feelings such as tiredness, nervousness, and hopelessness. Your score will help determine your mental health status and suggest appropriate resources.",
      estimatedTime: "4 minutes",
      questions: [
        {
          question:
            "During the last 30 days, about how often did you feel tired out for no good reason?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel nervous?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel so nervous that nothing could calm you down?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel hopeless?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel restless or fidgety?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel so restless you could not sit still?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel depressed?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel that everything was an effort?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel so sad that nothing could cheer you up?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
        {
          question:
            "During the last 30 days, about how often did you feel worthless?",
          options: [
            { text: "None of the time", score: 1 },
            { text: "A little of the time", score: 2 },
            { text: "Some of the time", score: 3 },
            { text: "Most of the time", score: 4 },
            { text: "All of the time", score: 5 },
          ],
        },
      ],

      scoring: {
        "10-19": {
          title: "Likely Well",
          description:
            "Individuals in this category are likely to be well. This indicates a generally positive mental state.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
        "20-24": {
          title: "Likely Mild Mental Disorder",
          description:
            "Individuals in this category may have a mild mental disorder. It may be beneficial to monitor your mental health and consider reaching out for support if needed.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
        "25-29": {
          title: "Likely Moderate Mental Disorder",
          description:
            "Individuals in this category may have a moderate mental disorder. It is advisable to seek support from a mental health professional.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
        "30-50": {
          title: "Likely Severe Mental Disorder",
          description:
            "Individuals in this category are likely to have a severe mental disorder. Immediate professional support is highly recommended.",
          tips: [],
          additionalNote: {
            text: "Recommended Reading:",
            link: {
              url: "/blogs/mental-health/balancing-academics-and-mental-health",
              text: "Balancing Academics and Mental Health",
            },
          },
        },
      },
    },
  ],
};

export default QuizData;
