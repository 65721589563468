import React from "react";
import Header from "../Home/Header";
import { MdArticle } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const QuizResult = ({ resultMessage }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-blue-100 min-h-svh sm:min-h-screen pt-16 md:pt-28 pb-4">
      <Header />
      <div className="max-w-2xl text-slate-700 font-medium mx-auto max-md:bg-blue-100 bg-white md:shadow-lg md:rounded-lg p-6 text-center font-montserrat">
        <div className="bg-white md:bg-blue-50 p-6 rounded-lg ">
          <h2 className="font-semibold text-xl md:text-2xl text-blue-800 pb-1 border-b-2 border-blue-200 mb-4">
            {resultMessage.title}
          </h2>
          <p className="text-gray-700 max-md:text-sm text-justify leading-relaxed">
            {resultMessage.description}
          </p>

          <div className="py-4 max-md:text-sm">
            <ul className="list-disc list-inside space-y-2">
              {resultMessage.tips &&
                resultMessage.tips.length > 0 &&
                resultMessage.tips.map((tip, index) => (
                  <li
                    key={index}
                    className="flex text-justify text-gray-700 gap-2"
                  >
                    <span className="pt-1">
                      <FaCircleCheck className="text-blue-600" />
                    </span>{" "}
                    <span>{tip}</span>
                  </li>
                ))}
            </ul>
          </div>

          <h2 className="font-semibold text-base md:text-lg text-blue-800 mt-4">
            {resultMessage.additionalNote.text}
          </h2>
          <div className="border border-blue-200 p-2 md:p-4 rounded-lg mt-2 bg-white transition-all">
            <Link
              className="text-blue-600 font-medium cursor-pointer flex items-center gap-2"
              to={resultMessage.additionalNote.link.url}
            >
              <MdArticle className="text-lg " />
              <span className="hover:underline ">
                {resultMessage.additionalNote.link.text}
              </span>
            </Link>
          </div>
        </div>

        <button
          onClick={() => navigate("/quizzes")}
          className="mt-6 w-full px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition duration-300 transform hover:scale-105"
        >
          Back to Quizzes
        </button>
      </div>
    </div>
  );
};

export default QuizResult;
